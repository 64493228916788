import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import axios from "axios";

// Define the AuthContext type
type AuthContextType = {
  user: any;
  isAuthenticated: boolean;
  accessToken: string | null;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  refreshAccessToken: () => Promise<string | null>;
};

// Create the AuthContext with default values
const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  accessToken: null,
  loading: true,
  login: async () => {},
  logout: () => {},
  refreshAccessToken: async () => null,
});

// Use the context
export const useAuth = () => {
  return useContext(AuthContext);
};

// AuthProvider component
export const AuthProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [user, setUser] = useState<any>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // Effect to check if the user is authenticated on mount
  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    const fetchUserData = async (token: string) => {
      try {
        // Fetch user data using the existing token
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const userData = response.data;
        setUser(userData);
        setIsAuthenticated(true);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        logout(); // Log out if unable to fetch user data
      } finally {
        setLoading(false); // Update loading state regardless of success/failure
      }
    };

    if (token) {
      setAccessToken(token);
      fetchUserData(token); // Fetch user data if token is present
    } else {
      setLoading(false); // No token means no need to fetch, just finish loading
    }
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/login`,
        { email, password },
        { withCredentials: true }
      );

      const {
        userId,
        accessToken,
        refreshToken,
        username,
        email: userEmail,
        role,
      } = response.data;

      // Store tokens and user data
      localStorage.setItem("accessToken", accessToken);

      // Update state
      setUser({ userId, username, userEmail, role });
      setAccessToken(accessToken);
      setIsAuthenticated(true);

      // Return success message and user data to the calling component
      return response.data;
    } catch (error: any) {
      // Handle and throw the error to the calling component
      if (error.response && error.response.data) {
        throw new Error(error.response.data.message || "Login failed");
      } else {
        throw new Error("Login failed");
      }
    }
  };

  const logout = () => {
    // Clear user data and tokens
    localStorage.removeItem("accessToken");
    document.cookie = "refreshToken=; Max-Age=0; path=/;";
    setUser(null);
    setAccessToken(null);
    setIsAuthenticated(false);
  };

  const refreshAccessToken = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/refresh-token`,
        {},
        { withCredentials: true } // Send the cookie
      );
      const { accessToken } = response.data;

      // Update context state
      setAccessToken(accessToken);
      localStorage.setItem("accessToken", accessToken);

      return accessToken;
    } catch (error) {
      console.error("Failed to refresh access token:", error);
      logout(); // Perform logout if refresh fails
      throw error; // Throw the error to handle in the API layer
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        accessToken,
        loading,
        login,
        logout,
        refreshAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
