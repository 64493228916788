import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthProvider"; // Import the AuthProvider context

const AuthFeature: React.FC = () => {
  const { isAuthenticated, loading } = useAuth(); // Access the auth state

  if (loading) {
    return <div>Loading...</div>; // Optionally, display a loading state while auth is being checked
  }

  // If the user is not authenticated, redirect to the login page
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Otherwise, render the protected routes
  return (
    <div className="account-page">
      <div className="main-wrapper">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthFeature;
