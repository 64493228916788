import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { Studentlist } from "../../../../core/data/json/studentList";
import { TableData } from "../../../../core/data/interface";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import StudentModals from "../studentModals";
import Table from "../../../../core/common/dataTable/index";
import { useApi } from "../../../../utils/api";

const StudentList = () => {
  const routes = all_routes;
  const data = Studentlist;

  const { AxiosGetWithToken, AxiosPostWithToken } = useApi();

  const [students, setStudents] = useState<any[]>([]);
  const [total, setTotal] = useState(0); // Total students count
  const [page, setPage] = useState(1); // Page number
  const [pageSize, setPageSize] = useState(10); // Items per page
  const [sortOrder, setSortOrder] = useState("name_asc"); // Sort order, e.g., 'name_asc'

  useEffect(() => {
    fetchStudents();
  }, [page, pageSize, sortOrder]); // Refetch data on page, pageSize, or sortOrder changes

  // Fetch students from the API with pagination and sorting
  const fetchStudents = async () => {
    try {
      const response = await AxiosGetWithToken("/student", {
        page,
        limit: pageSize,
        sortBy: sortOrder,
      });
      if (response && response.students) {
        setStudents(response.students);
        setTotal(response.total);
      }
    } catch (error) {
      console.error("Failed to fetch students:", error);
    }
  };

  // Handle Table change
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.order) {
      const order = sorter.order === "ascend" ? "_asc" : "_desc"; // Define sort order
      setSortOrder(`${sorter.field}${order}`);
    } else {
      setSortOrder(""); // Reset sort order if no sorter is selected
    }
    setPage(pagination.current); // Update page number
    setPageSize(pagination.pageSize); // Update page size
  };

  const columns = [
    {
      title: "Admission No",
      dataIndex: "admissionNo",
      render: (text: string, record: any) => (
        <Link
          to={routes.studentDetail}
          className="link-primary"
          state={{ studentId: record._id }}
        >
          {text}
        </Link>
      ),
      sorter: true,
    },
    {
      title: "Roll No",
      dataIndex: "rollNo",
      sorter: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text: any, record: any) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">{text?.english}</Link>
            </p>
          </div>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Class",
      dataIndex: "class",
      render: (classInfo: any) => classInfo?.name || "N/A",
      sorter: true,
    },
    {
      title: "Section",
      dataIndex: "section",
      render: (sectionInfo: any) => sectionInfo?.name || "N/A",
      sorter: true,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: true,
    },
    {
      title: "Admission Date",
      dataIndex: "admissionDate",
      sorter: true,
      render: (date: string) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Date of Birth",
      dataIndex: "dateOfBirth",
      sorter: true,
      render: (date: string) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Father's Phone Number",
      dataIndex: ["father", "mobileNumber"],
      sorter: true,
    },
    {
      title: "Action",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <div className="dropdown">
            <Link
              to="#"
              className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="ti ti-dots-vertical fs-14" />
            </Link>
            <ul className="dropdown-menu dropdown-menu-right p-3">
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to={`${routes.studentDetail}/${record._id}`}
                >
                  <i className="ti ti-menu me-2" />
                  View Student
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to={`${routes.editStudent}/${record._id}`}
                >
                  <i className="ti ti-edit-circle me-2" />
                  Edit
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target={`#delete-modal-${record._id}`}
                >
                  <i className="ti ti-trash-x me-2" />
                  Delete
                </Link>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Students List</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">Students</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Students
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <div className="mb-2">
                <Link
                  to={routes.addStudent}
                  className="btn btn-primary d-flex align-items-center"
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Student
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Students List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Students List</h4>
            </div>
            <div className="card-body p-0 py-3">
              {/* Student List */}
              <Table
                dataSource={students}
                columns={columns}
                Selection={true} // If row selection is needed
                pagination={{
                  current: page,
                  pageSize: pageSize,
                  total: total,
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                  nextIcon: <span>Next</span>,
                  prevIcon: <span>Prev</span>,
                }}
                onChange={handleTableChange} // Handle sort and pagination changes
              />
              {/* /Student List */}
            </div>
          </div>
          {/* /Students List */}
        </div>
      </div>
      {/* /Page Wrapper */}
      <StudentModals />
    </>
  );
};

export default StudentList;
