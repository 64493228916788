import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { StudentSidebarProps } from "../../../../core/data/interface";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const StudentSidebar = ({ student }: StudentSidebarProps) => {
  const formattedDob = dayjs(student!.dateOfBirth).local().format("LL");
  const formattedAdmissionDate = dayjs(student!.admissionDate)
    .local()
    .format("LL");

  return (
    <div className="col-xxl-3 col-xl-4 theiaStickySidebar">
      <div className="stickybar pb-4">
        <div className="card border-white">
          <div className="card-header">
            <div className="d-flex align-items-center flex-wrap row-gap-3">
              <div className="overflow-hidden">
                <h4 className="mb-1 text-truncate">{`${
                  student!.name.english
                }`}</h4>
                <p className="text-primary">{`Class: ${
                  student!.class.name
                } Section: ${student!.section.name}`}</p>
              </div>
            </div>
          </div>
          {/* Basic Information */}
          <div className="card-body">
            <h5 className="mb-3">Basic Information</h5>
            <dl className="row mb-0">
              <dt className="col-6 fw-medium text-dark mb-3">Admission No</dt>
              <dd className="col-6 mb-3">{student!.admissionNo}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Admission Date</dt>
              <dd className="col-6 mb-3">{formattedAdmissionDate}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">EMIS No</dt>
              <dd className="col-6 mb-3">{student!.emisNo}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Name in Tamil</dt>
              <dd className="col-6 mb-3">{student!.name.tamil}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Gender</dt>
              <dd className="col-6 mb-3">{student!.gender}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Date of Birth</dt>
              <dd className="col-6 mb-3">{formattedDob}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Blood Group</dt>
              <dd className="col-6 mb-3">{student!.bloodGroup}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Nationality</dt>
              <dd className="col-6 mb-3">{student!.nationality}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">State</dt>
              <dd className="col-6 mb-3">{student!.state}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Religion</dt>
              <dd className="col-6 mb-3">{student?.religion}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Community</dt>
              <dd className="col-6 mb-3">{student?.community}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Caste</dt>
              <dd className="col-6 mb-3">{student?.caste}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Mother Tongue</dt>
              <dd className="col-6 mb-3">{student?.motherTongue}</dd>
            </dl>
          </div>
          {/* /Basic Information */}
        </div>

        {/* Sibiling Information */}
        {student?.siblings.length != 0 && (
          <div className="card border-white">
            <div className="card-body">
              <h5 className="mb-3">Sibiling Information</h5>
              {student?.siblings.map((sibling, index) => {
                return (
                  <div className="d-flex align-items-center bg-light-300 rounded p-3 mb-3">
                    <div className="ms-2">
                      <h5 className="fs-14">{sibling.name}</h5>
                      <p style={{ marginBottom: "0" }}>{sibling.relation}</p>
                      <p>{`Class: ${sibling.class} Section: ${sibling.section}`}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {/* /Sibiling Information */}
      </div>
    </div>
  );
};

export default StudentSidebar;
