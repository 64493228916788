import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { DatatableProps } from "../../data/interface"; // Ensure correct path

const Datatable: React.FC<DatatableProps> = ({
  columns,
  dataSource,
  Selection,
  pagination, // New pagination prop
  onChange, // New onChange prop to handle sorting and pagination
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [Selections, setSelections] = useState<any>(true);
  const [filteredDataSource, setFilteredDataSource] = useState(dataSource);

  const onSelectChange = (newSelectedRowKeys: any[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    const filteredData = dataSource.filter((record) =>
      Object.values(record).some((field) =>
        String(field).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredDataSource(filteredData);
  };

  useEffect(() => {
    setSelections(Selection);
  }, [Selection]);

  // Sync filtered data with dataSource whenever dataSource updates
  useEffect(() => {
    setFilteredDataSource(dataSource);
  }, [dataSource]);

  return (
    <>
      <div className="table-top-data d-flex px-3 justify-content-between">
        <div className="page-range"></div>
        <div className="serch-global text-right">
          <input
            type="search"
            className="form-control form-control-sm mb-3 w-auto float-end"
            value={searchText}
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            aria-controls="DataTables_Table_0"
          />
        </div>
      </div>
      {!Selections ? (
        <Table
          className="table datanew dataTable no-footer"
          columns={columns}
          dataSource={filteredDataSource}
          pagination={{
            ...pagination, // Use pagination provided by the parent component
            locale: { items_per_page: "" },
            nextIcon: <span>Next</span>,
            prevIcon: <span>Prev</span>,
          }}
          onChange={onChange} // React to pagination or sorting changes from the parent component
        />
      ) : (
        <Table
          className="table datanew dataTable no-footer"
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          columns={columns}
          dataSource={filteredDataSource}
          pagination={{
            ...pagination, // Use pagination provided by the parent component
            locale: { items_per_page: "" },
            nextIcon: <span>Next</span>,
            prevIcon: <span>Prev</span>,
          }}
          onChange={onChange} // React to pagination or sorting changes from the parent component
        />
      )}
    </>
  );
};

export default Datatable;
