import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { useAuth } from "../../../context/AuthProvider";

type PasswordField = "password";

const Login3 = () => {
  const { login, isAuthenticated } = useAuth();
  const routes = all_routes;
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
  });

  useEffect(() => {
    // If the user is already authenticated, redirect to the admin dashboard
    if (isAuthenticated) {
      navigate(routes.studentList);
    }
  }, [isAuthenticated, navigate, routes.adminDashboard]);

  const togglePasswordVisibility = (field: PasswordField) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await login(email, password);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error in Post Request", error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5 mx-auto">
            <form>
              <div className="d-flex flex-column justify-content-between vh-100">
                <div className=" mx-auto p-4 text-center">
                  <ImageWithBasePath
                    src="assets/img/icons/lions-img.jpg"
                    className="img-fluid"
                    alt="Logo"
                    width={100}
                  />
                </div>
                <div className="card">
                  <div className="card-body p-4">
                    <div className=" mb-4">
                      <h2 className="mb-2">Welcome</h2>
                      <p className="mb-0">
                        Please enter your details to sign in
                      </p>
                    </div>
                    <div className="mb-3 ">
                      <label className="form-label">Email Address</label>
                      <div className="input-icon mb-3 position-relative">
                        <span className="input-icon-addon">
                          <i className="ti ti-mail" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          value={email}
                          onChange={(input) => {
                            setEmail(input.target.value);
                          }}
                        />
                      </div>
                      <label className="form-label">Password</label>
                      <div className="pass-group">
                        <input
                          type={
                            passwordVisibility.password ? "text" : "password"
                          }
                          className="pass-input form-control"
                          value={password}
                          onChange={(input) => {
                            setPassword(input.target.value);
                          }}
                        />
                        <span
                          className={`ti toggle-passwords ${
                            passwordVisibility.password
                              ? "ti-eye"
                              : "ti-eye-off"
                          }`}
                          onClick={() => togglePasswordVisibility("password")}
                        ></span>
                      </div>
                    </div>
                    <div className="form-wrap form-wrap-checkbox mb-3">
                      <div className="d-flex align-items-center">
                        <div className="form-check form-check-md mb-0">
                          <input
                            className="form-check-input mt-0"
                            type="checkbox"
                          />
                        </div>
                        <p className="ms-1 mb-0 ">Remember Me</p>
                      </div>
                      <div className="text-end ">
                        <p
                          data-bs-toggle="modal"
                          data-bs-target="#standard-modal"
                          className="link-danger"
                          style={{ cursor: "pointer" }}
                        >
                          Forgot Password?
                        </p>
                      </div>
                    </div>
                    <div className="mb-3">
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        onClick={handleLogin}
                      >
                        {isLoading ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <p>Sign In</p>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="p-4 text-center">
                  <p className="mb-0 ">Copyright © 2024 - Aveers</p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          id="standard-modal"
          className="modal fade"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="standard-modalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="standard-modalLabel">
                  Forgot Password
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p>
                  To reset your password, please contact your system
                  administrator. They will assist you with securing access to
                  your account.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Understood
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login3;
