import React, { useEffect, useState } from "react";
import Select from "react-select";

export type Option = {
  value: string;
  label: string;
};

export interface SelectProps {
  options: Option[];
  defaultValue?: Option;
  className?: string;
  styles?: any;
  value?: string;
  onChange?: (selectedOption: Option | null) => void; // Add onChange as a prop
}

const CommonSelect: React.FC<SelectProps> = ({
  options,
  defaultValue,
  className,
  value,
  onChange,
}) => {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    defaultValue
  );

  const handleChange = (option: Option | null) => {
    setSelectedOption(option || undefined);
    if (onChange) {
      onChange(option); // Trigger the parent's onChange handler if provided
    }
  };

  useEffect(() => {
    // If a value prop is provided, find the corresponding option
    const matchedOption = options.find((option) => option.value === value);
    setSelectedOption(matchedOption || defaultValue || undefined);
  }, [value, defaultValue, options]);

  return (
    <Select
      classNamePrefix="react-select"
      className={className}
      options={options}
      value={selectedOption}
      onChange={handleChange}
      placeholder="Select"
    />
  );
};

export default CommonSelect;
