import axios from "axios";
import { useAuth } from "../context/AuthProvider";

// Base URL is fetched from the .env file
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Axios instance for making requests
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const useApi = () => {
  const { accessToken, refreshAccessToken, logout } = useAuth();

  const handleAuthError = async (error: any) => {
    if (error.response && error.response.status === 401) {
      try {
        // Attempt to refresh the access token
        const newAccessToken = await refreshAccessToken();

        // Retry the original request with the new access token
        const originalRequest = error.config;
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return await api(originalRequest); // Use the api instance to retry the request
      } catch (refreshError) {
        logout(); // Log out if unable to refresh access token
        throw refreshError;
      }
    } else {
      throw error; // If it's not a 401 error, rethrow the error
    }
  };

  // Request with Token - AxiosPostWithToken
  const AxiosPostWithToken = async (route: string, data: any) => {
    try {
      const response = await api.post(route, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return await handleAuthError(error);
    }
  };

  // Request with Token - AxiosGetWithToken
  const AxiosGetWithToken = async (route: string, params?: any) => {
    try {
      const response = await api.get(route, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params,
      });
      return response.data;
    } catch (error) {
      return await handleAuthError(error);
    }
  };

  // Request with Token - AxiosPutWithToken
  const AxiosPutWithToken = async (route: string, data: any) => {
    try {
      const response = await api.put(route, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return await handleAuthError(error);
    }
  };

  // Request with Token - AxiosDeleteWithToken
  const AxiosDeleteWithToken = async (route: string) => {
    try {
      const response = await api.delete(route, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return await handleAuthError(error);
    }
  };

  // Request without Token - Post
  const post = async (route: string, data: any) => {
    try {
      const response = await api.post(route, data);
      return response.data;
    } catch (error) {
      console.error("Post Request Failed", error);
      throw error;
    }
  };

  // Request without Token - Get
  const get = async (route: string) => {
    try {
      const response = await api.get(route);
      return response.data;
    } catch (error) {
      console.error("Get Request Failed", error);
      throw error;
    }
  };

  return {
    AxiosPostWithToken,
    AxiosGetWithToken,
    AxiosPutWithToken,
    AxiosDeleteWithToken,
    post,
    get,
  };
};
